@import "styles/variables.scss";

.label {
    font-size: 18px;
    font-weight: 600;
}

.bodyOpen {
    overflow-y: hidden;
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* background: rgba(243, 246, 255, 0.3); */
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 300ms ease-in-out;
    opacity: 0;
    z-index: $z-index-modal;
    padding: 20px;
}

.overlayAfterOpen {
    opacity: 1;
}

.overlayBeforeClose {
    opacity: 0;
}

.modal {
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 0;
    outline: none;
    box-shadow: 0px 10px 40px rgba(51, 50, 92, 0.03);
    border-radius: 10px;
    position: relative;
    border: none;
    transition: opacity 300ms ease-in-out;
    opacity: 0;
    min-height: 200px;
    overflow: hidden;
    padding: 0 10px 30px 10px;
    max-width: 95%;
    width: 100%;
    margin-top: 0;
}

.modalAfterOpen {
    opacity: 1;
    max-height: 95%;
}

.modalBeforeClose {
    opacity: 0;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 16px 40px;
    background-color: white;
    width: 100%;
    height: 100%;
    min-height: 60px;
}

.modalCloseButton {
    position: absolute;
    top: 15px;
    right: 10px;
    border: none;
    cursor: pointer;
    font-size: 30px;
    height: 30px;
    width: 40px;
    line-height: 40px;
    background-color: transparent;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalBody {
    overflow: auto;
    max-height: 80vh;
}
